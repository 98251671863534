import React from "react";
import { graphql } from 'gatsby'
import Header from "../components/Header/Header"
import CareersDetailsPage from '../components/CareerDetailsPage/CareerDetailsPage'
import Footer from "../components/Footer/Footer"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";

const NewsTemplate = (props) => {
  const GQLTeams = props.data.glstrapi;
  return(
    <div className="career-details-main">
      <Header/>
        <CareersDetailsPage mydata={GQLTeams} />
      <Footer/>
    </div>
  )
}

export default NewsTemplate


export const pageQuery = graphql`
  query CareersQuery($slug: ID!) {
    glstrapi {  
      currentVacancies(where:{id: $slug}){
        salary
        Title
        URL
        Video_url
        image {
          url
        }
        contents
      }
    
      globalModule {
        Career_Sidebar_Video_Background_Image {
          url
          alternativeText
        }
        Career_Sidebar_Content
        Career_Sidebar_Video_URL
      }
    }
  }
`