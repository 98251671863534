import React, { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import CVForm from "../StaticPage/ContactUs/ContactUs"
import parse from 'html-react-parser';
import StaticBannerVideo from "../StaticPage/AreaGuideBanner"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Instagram from "../Instagram"
import logoBlack from "../../images/redbrik-new-logo.svg"
import $ from "jquery"
import SEO from "../seo"
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import ReactPlayer from 'react-player'

import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share"; 


function CareerDetailsPage(props) { 

      const applyjob = () => (
        $('html, body').animate({
            scrollTop: $("#career-form").offset().top
        }, 1000)
      )

      const shareurl = typeof window !== 'undefined' ? window.location.href : ''
      const [Shareicons, setShareicons] = React.useState(false);
  
      const openShareicons = () => {
          setShareicons(true);
          if (Shareicons === true) {
              setShareicons(false);
          }
      }  

      const getBreadcrumb = (list) => {
        let bread = [
            { Title: "About", Slug: "about-us/" },
            { Title: "careers", Slug: "about-us/careers/" }]
        if (list && list.length > 0) {
            bread.push({ Title: list[0].Title, Slug: `about-us/property-news/${list[0].URL}` })
        }
        return bread;
    } 
   
    return (
        <React.Fragment>
            <div className="news-details career-details-page">
                <Header />
                <div className="collection-landing news-details">
                    <Breadcrumb staticList={getBreadcrumb(props.mydata.currentVacancies)} />
                    {props.mydata.currentVacancies.map((data, index) => {                  
                       
                        return (
                            <Container className="content">
                                <SEO
                                    title={`${data.Title}`}
                                    description={`${data.Contents}`}
                                />

                                <Row>
                                    <Col lg={1}>
                                    </Col>
                                    <Col lg={10}>
                                        <div className="heading">                                          
                                            <div className="main-title">
                                                <h1>{data.Title}</h1>
                                                <Row>
                                                    <Col lg={8}>
                                                        <div className="social">
                                                        <a href="javascript:void(0)" className="propertyShare" onClick={openShareicons}>Share this post</a>
                                                        {Shareicons &&
                                                            <div onClick={openShareicons} className="social-share">
                                                                <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                                                    <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                                </FacebookShareButton>
                                                                <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                                                    <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                                </TwitterShareButton>

                                                            </div>
                                                        }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        {data.image &&
                                            <div className="news-main-banner">
                                                { data.Video_url?<div className="ifram-container"><ReactPlayer url={data.Video_url} controls={true} /></div>
                                                :<img src={data.image.url} alt={data.Title} />
                                                }
                                            </div>
                                        }                                       
                                        {data.contents &&
                                            <div className="row">
                                                <div className="static-content col-lg-12">
                                                    {parse(data.contents)}
                                                    <a class="btn btn_cray" href="/about-us/send-us-your-cv/">Send us your CV</a>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={1}>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })}                    
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default CareerDetailsPage;
